body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text{
  margin: 0;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 1 !important;
  color: #2d2d2d !important;
  font-size: 16px;
  margin-top: 40px;
  text-align: justify;
}

input[type="file"]::-webkit-file-upload-button {
  /* background-color: rgb(221, 221, 221);
  color: rgb(65, 65, 65);
  width: 100%;
  padding: 1vmax;
  border-radius: 30px;
  border: none; */
  font-size: 16px;
  /* font-family: 'Open Sans', sans-serif; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; 
   margin: 0;
}

 /* Firefox */
 input[type=number] {
  -moz-appearance: textfield;
} 
.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  border-top: 3px solid #bccf17;
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 0px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover, .dropdown:hover .dropbtn {
  /* background-color: #555; */
  color: white;
}

/* .dropdown-content a:hover {
  background-color: #ddd;
  color: black;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

/* @media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
} */

/* @media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
} */

.mdropdown {
  /* float: left; */
  /* overflow: hidden; */
}

.mdropdown .mdropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.mdropdown-content {
  display: none;
  /* position: absolute; */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  border-top: 3px solid #bccf17;
  z-index: 1;
}

.mdropdown-content a {
  float: none;
  color: black;
  padding: 0px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.mtopnav a:hover, .mdropdown:hover .mdropbtn {
  /* background-color: #555; */
  color: white;
}

/* .dropdown-content a:hover {
  background-color: #ddd;
  color: black;
} */

.mdropdown:hover .mdropdown-content {
  display: block;
}